import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from './utils/axios'
import Select2 from '@/components/fragments/Select2'
import './assets/css/style.css'

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.component('SelectTwo', Select2);
app.use(router).use(store).mount('#app')