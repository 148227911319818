const userkey = 'pengajian_app';

const setUserToLocal = (value) => {
    localStorage.setItem(userkey, JSON.stringify(value));
    return isInLocal(userkey);
}

const isInLocal = (key) => {
    return localStorage.getItem(key) !== null;
}

const getUserFromLocal = (prop = null) => {
    const user = JSON.parse(localStorage.getItem(userkey));
    if (prop) return user[prop];
    return user;
}

const removeUserFromLocal = () => {
    localStorage.removeItem(userkey);
    return !isInLocal(userkey);
}

export { setUserToLocal, isInLocal, getUserFromLocal, removeUserFromLocal };