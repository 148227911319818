import axios from "axios";

const hostname = window.location.hostname;
const hostnamePartials = hostname.split('.');
const accessFromLocal = hostnamePartials[2] == 0;

// Development
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";

// Production
const localApiUrl = 'http://192.168.0.123:8016/api';
const nginxApiUrl = 'https://absenpengajian.rsuaminahblitar.com/jadwal-pengajian-api';
axios.defaults.baseURL = accessFromLocal ? localApiUrl : nginxApiUrl;

export default axios;
