import { createRouter, createWebHistory } from 'vue-router'
import { getUserFromLocal } from '@/utils/local'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/user-dashboard-jadwal",
    name: "UserDashboardView",
    component: () => import("../views/user/DashboardView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user-dashboard-resumes",
    name: "UserRiwayatResumeView",
    component: () => import("../views/user/RiwayatResumeView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user-profile-akun",
    name: "UserAkunView",
    component: () => import("../views/user/AkunView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin-dashboard-jadwal",
    name: "AdminDashboardView",
    component: () => import("../views/admin/DashboardView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin-dashboard-jadwal-detail",
    name: "AdminDetailJadwaView",
    component: () => import("../views/admin/DetailJadwalView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin-dashboard-karyawan",
    name: "ListKaryawanView",
    component: () => import("../views/admin/ListKaryawanView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin-dashboard-karyawan-detail",
    name: "DetailKaryawanView",
    component: () => import("../views/admin/DetailKaryawanView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/print-employee-recap",
    name: "RekapKeaktifan",
    component: () => import("../views/admin/docs/RekapKeaktifan.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getUserFromLocal();  // TODO: check if user is authenticated
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (to.path === "/login" && isAuthenticated) {
    switch (isAuthenticated.me.role.name) {
      case 'Admin':
        next("/admin-dashboard-jadwal");
        break;
    
      case 'User':
        next("/user-dashboard-jadwal");
        break;

      default:
        next();
        break;
    }
  } else {
    next();
  }
});

export default router
