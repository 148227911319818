import { createStore } from 'vuex'
import persistedStateVuex from "persisted-state-vuex";

export default createStore({
  plugins: [persistedStateVuex.init],
  state: {
    schedule: null,
    employee: null
  },
  getters: {
    getSchedule: (state) => state.schedule,
    getEmployee: (state) => state.employee,
  },
  mutations: {
    setSchedule(state, newSchedule) {
      state.schedule = newSchedule;
    },
    setEmployee(state, newEmployee) {
      state.employee = newEmployee;
    },
  },
  actions: {
    updateSchedule({ commit }, newSchedule) {
      commit('setSchedule', newSchedule);
    },
    updateEmployee({ commit }, newEmployee) {
      commit('setEmployee', newEmployee);
    },
  },
  modules: {
  }
})
