<template>
    <label for="select2">
        <slot></slot>
    </label>
    <div v-click-outside="closeList" class="relative">
        <input id="select2" v-model="input" @click="showData()" @keyup="setInput()" type="text" class="form-control"
            :placeholder="placeholder || ''">
        <div v-if="isShow" class="absolute box card">
            <p v-if="showLoader" class="">Loading...</p>
            <p v-else @click="setItem(item)" v-for="item in items" :key="item" class="option">{{
                item[option.toShow] }}</p>
        </div>
    </div>
</template>

<script>
import ClickOutsideDirective from '@/components/fragments/Select2/click-outside-directive';

export default {
    name: 'SelectInput2',
    props: {
        initInput: {
            type: String,
            default: '',
        },
        allItem: {
            type: Array,
            required: true,
        },
        option: {
            type: Object,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        'click-outside': ClickOutsideDirective,
    },
    data() {
        return {
            input: '',
            isShow: false,
        }
    },
    methods: {
        showData() {
            this.isShow = !this.isShow;
        },
        setItem(item) {
            if (!this.option.toGet) this.$emit('get-item', item);
            else this.$emit('get-item', item[this.option.toGet]);

            this.input = item[this.option.toShow];
            this.isShow = false;
        },
        setInput() {
            this.$emit('get-input', this.input);
        },
        closeList() {
            this.isShow = false;
        },
    },
    computed: {
        items() {
            return this.allItem.filter(item => item[this.option.toShow].toLowerCase().includes(this.input.toLowerCase()));
        },
        init() {
            return this.initInput;
        },
        showLoader() {
            return this.isLoading;
        },
    },
    watch: {
        init: {
            handler(value) {
                this.input = value;
            },
            immediate: true,
        },
    },
}
</script>

<style>
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.w-full {
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

.box {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 10rem;
    background-color: #fff;
    z-index: 1;
}

.loader, .option {
    padding: .25rem 1rem;
}

.option {
    cursor: pointer;
}

.option:hover {
    background-color: #3490dc;
    color: #fff;
}
</style>